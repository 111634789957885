<template>
  <v-col :cols="12" class="pa-2">
    <div class="grey--text text-right">{{ label }}</div>
    <v-autocomplete
      :background-color="$attrs.disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      v-model="inputVal"
      :search-input.sync="search"
      v-bind="$attrs"
      multiple
      clearable
      :items="tags"
      itemValue="id"
      itemText="name"
      append-outer-icon="mdi-plus-circle"
      :disabled="loading"
      :loading="loading"
      @click:append-outer="createTag"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click:close="remove(data.item)"
          color="orange darken-3"
          dark
        >
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
export default {
  data() {
    return {
      tags: [],
      loading: true,
      search: "",
    };
  },
  mounted() {
    this.getTags();
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    remove(item) {
      const index = this.inputVal.indexOf(item.id);
      if (index >= 0) this.inputVal.splice(index, 1);
    },
    getTags() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/tags/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          this.tags = response.data;
          this.inputVal = this.inputVal.filter(
            (val) => !!response.data?.find((tag) => tag?.id == val)
          );
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    createTag() {
      if (!this.search || !this.search.length) return;

      if (this.tags.find((val) => val.name == this.search)) {
        this.$root.$emit("toast", {
          text: "تگ با این اسم موجود است.",
        });
        return;
      }

      if (!this.loading) this.loading = true;

      let data = new FormData();

      data.append("name", this.search);
      data.append(
        "slug",
        this.search.replace(/\/|\s|\./g, "-") + Math.floor(Math.random() * 100)
      );

      MyAxios.post("tags/create", data)
        .then((response) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "تگ با موفقیت ایجاد شد.",
            type: "success",
          });
          if (response.data?.id) {
            this.inputVal = [...this.inputVal, response.data?.id];
            this.search = "";
          }
          this.getTags();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
          });
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
